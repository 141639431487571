import styled from 'styled-components'

const Content = styled.section`
  width: ${({ width = '100%' }) => width};
  height: 100%;
  display: flex;
  flex-direction: ${({ direction = 'row' }) => direction};
  align-items: center;
  justify-content: space-around;
`

export default Content
