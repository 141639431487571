import styled from 'styled-components'

const ImageContainer = styled.div`
  display: block;
  width: 20%;

  @media (max-width: 700px) {
    width: 80%;
    margin-bottom: 1rem;
  }
`

export default ImageContainer
